import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bench Press 4×6\\@65% with :02 Pause on Chest `}<em parentName="p">{`(speed on ascent)`}</em></p>
    <p>{`Strict Pullups 4×6 `}<em parentName="p">{`(speed on ascent)`}</em></p>
    <p>{`then,`}</p>
    <p>{`21-Clapping Pushups`}</p>
    <p>{`21-Bodyweight Rows `}<em parentName="p">{`(lying underneath racked barbell at hip height)`}</em></p>
    <p>{`21-GHD Situps`}</p>
    <p>{`400M Run`}</p>
    <p>{`15-Clapping Pushups`}</p>
    <p>{`15-Bodyweight Rows`}</p>
    <p>{`15-GHD Situps`}</p>
    <p>{`400M Run`}</p>
    <p>{`9-Clapping Pushups`}</p>
    <p>{`9-Bodyweight Rows`}</p>
    <p>{`9-GHD Situps`}</p>
    <p>{`400M Run`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      